import {useDispatch, useSelector} from "react-redux";
import React, {useState, memo} from 'react';

import {userRecoverPassword} from "store/AuthV2/AuthV2Slice";
import {useLocalStorage} from "hooks";

import ProfileInfoHeader from "./ProfileInfoHeader";
import {Button} from "components/atoms";

const ProfilePasswordBlock = () => {
    const dispatch = useDispatch()
    const {getLoginType} = useLocalStorage()

    const [isDisabled, setIsDisabled] = useState(false)

    const {success, error, errorMessage} = useSelector(state => state.authV2.recoverPassword)
    const {userData} = useSelector(state => state.authV2.signIn)

    const requestPassword = () => {
        setIsDisabled(true)
        dispatch(userRecoverPassword({username: userData.username}))
        setTimeout(() => setIsDisabled(false), 60000)
    }

    const status = error ? "error" : "default"
    const processInfo = success ? "Password requested!" : error ? errorMessage : ""
    const isLoginTypeSocial = getLoginType() === "social"

    return !isLoginTypeSocial && (
        <div className="profile_container_info_password">
            <ProfileInfoHeader
                status={status}
                title="Password"
                processInfo={processInfo}
            />
            <Button
                view="white"
                disabled={isDisabled}
                onClick={requestPassword}
            >
                Request Password
            </Button>
        </div>
    )
}

export default memo(ProfilePasswordBlock)