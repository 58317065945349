import React from 'react';
import {CountDownWithContainer} from "components/molecules";

const DeadlineCountdown = ({data, isLoading}) => {
    const inventoryExpirationDate = data?.expiration_date || false

    return (
        <div className="shop-divine_content_deadline">
            <CountDownWithContainer
                day={true}
                loading={isLoading}
                success={!isLoading && data}
                date={inventoryExpirationDate}
                text="This item will never be sold again!"
            />
        </div>
    )
}

export default DeadlineCountdown