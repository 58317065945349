import {useDispatch, useSelector} from "react-redux";
import React, {useState, memo} from "react";

import {globalService} from "store/Global/globalService";
import {useAuthorization, useDebounce} from "hooks";
import {ERROR, STATUS, SUCCESS, wait} from "utils";
import {setData} from "store/AuthV2/AuthV2Slice"

import ProfileInfoHeader from "./ProfileInfoHeader";
import {Input, Button} from "components/atoms";

const ProfileNicknameBlock = () => {
    const dispatch = useDispatch()

    const [processInfo, setProcessInfo] = useState({status: STATUS.DEFAULT, message: ""})
    const [isNicknameAddedSuccessfully, setIsNicknameAddedSuccessfully] = useState(false)

    const {userData} = useSelector(state => state.authV2.signIn);

    const debounce = useDebounce()
    const {accessToken} = useAuthorization()

    const [nickname, setNickname] = useState(userData.nickname)
    const [isDisable, setIsDisable] = useState(true)

    const updateProcessInfo = (status, message: "") => setProcessInfo({status, message})

    const checkNickname = async (nickname) => {
        setIsDisable(true)

        try {
            const isSameNickname = nickname === userData.nickname;
            const isEmptyNickname = !nickname;

            if (isSameNickname || isEmptyNickname) {
                updateProcessInfo(STATUS.DEFAULT);
                return;
            }

            await globalService.checkNickname(nickname)

            setIsDisable(false)
            updateProcessInfo(STATUS.SUCCESS, SUCCESS.NICKNAME_VALID);
        } catch (error) {
            updateProcessInfo(STATUS.ERROR, error?.message ?? ERROR.WRONG)
        }
    }

    const handleNicknameChange = (value) => {
        setNickname(value)
        debounce(() => checkNickname(value), 700)
    }

    const updateNickname = async () => {
        setIsDisable(true)
        try {
            await globalService.updateNickname(nickname, accessToken)

            dispatch(setData({path1: "signIn", path2: "userData", data: {...userData, nickname}}))
            updateProcessInfo(STATUS.DEFAULT, SUCCESS.NICKNAME_UPDATED)
            setIsNicknameAddedSuccessfully(true)

            await wait(3000)

            setIsNicknameAddedSuccessfully(false)
            updateProcessInfo(STATUS.DEFAULT)
        } catch (error) {
            updateProcessInfo(STATUS.ERROR, error?.message ?? ERROR.WRONG)
        }
    }

    return (
        <div className="profile_container_info_nickname">
            <ProfileInfoHeader title="Nickname" status={processInfo.status} processInfo={processInfo.message}/>
            <Input
                value={nickname}
                disabled={isDisable}
                onChange={(e) => handleNicknameChange(e.target.value)}
                icon={isNicknameAddedSuccessfully ? "successCheck" : "editV2"}
            />
            <ul className="profile_container_info_nickname_list">
                <li>Must be between 3 and 16 characters</li>
                <li>May contain letters, numbers, non-consecutive dashes, periods, underscores, and spaces.</li>
            </ul>
            <Button disabled={isDisable} view="white" onClick={updateNickname}>Update nickname</Button>
        </div>
    )
}

export default memo(ProfileNicknameBlock)