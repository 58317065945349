import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";

import {globalService} from "store/Global/globalService";
import {userGetData} from "store/AuthV2/AuthV2Slice";
import {useDebounce, useLocalStorage} from "hooks";
import {ERROR, STATUS, SUCCESS} from "utils";

import ModalWindowTemplate from "components/molecules/ModalWindowTemplate";
import {Input, Button, LoadSpinner} from "components/atoms";

const StepNickname = ({show, changeStep}) => {
    const debounce = useDebounce()
    const dispatch = useDispatch()
    const {removeToken, setToken} = useLocalStorage()

    const {userData} = useSelector(state => state.authV2.signIn)

    const [processInfo, setProcessInfo] = useState({status: STATUS.DEFAULT, message: ""})
    const [isChecking, setIsChecking] = useState(false)
    const [isDisable, setIsDisable] = useState(true)
    const [nickname, setNickname] = useState("")

    const updateProcessInfo = (status, message: "") => setProcessInfo({status, message})

    const checkNickname = async (nickname) => {
        setIsDisable(true)

        try {
            const isSameNickname = nickname === userData.nickname;
            const isEmptyNickname = !nickname;

            if (isSameNickname || isEmptyNickname) {
                updateProcessInfo(STATUS.DEFAULT);
                return;
            }

            setIsChecking(true)
            await globalService.checkNickname(nickname)

            setIsDisable(false)
            setIsChecking(false)
            updateProcessInfo(STATUS.SUCCESS, SUCCESS.NICKNAME_VALID);
        } catch (error) {
            setIsChecking(false)
            updateProcessInfo(STATUS.ERROR, error?.message ?? ERROR.WRONG)
        }
    }

    const handleNicknameChange = (value) => {
        setNickname(value)
        debounce(() => checkNickname(value), 500)
    }

    const updateNickname = async () => {
        setIsDisable(true)
        try {
            setIsChecking(true)

            await globalService.updateNickname(nickname, userData.accessToken)
            dispatch(userGetData(userData.accessToken))
            setToken(userData.accessToken)

            window._paq.push(['trackEvent', 'Nickname', 'Success'])
            window._paq.push(['trackEvent', 'Nickname-more', `${nickname}`])

            changeStep("step7")
        } catch (error) {
            setIsChecking(false)
            updateProcessInfo(STATUS.ERROR, error?.message ?? ERROR.WRONG)
        }
    }

    const signOut = () => {
        removeToken()
        changeStep("")
        window.location.reload()
    }

    return (
        <React.Fragment>
            <ModalWindowTemplate
                show={show}
                isShowClose={false}
                className="auth-step_step14"
                title={<span>Create Your <br/> KOMPETE Nickname</span>}
            >
                <p>This is your in-game name that everyone will see. Nicknames can be changed later in your profile.</p>
                <div className="auth-step_step14_loadSpinner-block">{isChecking && <LoadSpinner/>}</div>
                <Input
                    type="text"
                    label="nickname"
                    name="nickname"
                    value={nickname}
                    placeholder="Nickname"
                    onChange={(e) => handleNicknameChange(e.target.value)}
                    error={processInfo.status === STATUS.ERROR && processInfo.message}
                    status={processInfo.status === STATUS.ERROR ? STATUS.ERROR : STATUS.DEFAULT}
                />
                <div className="auth-step_step14_nickname-rules">
                    <ul>
                        <li>Must be between 3 and 16 characters</li>
                        <li>May contain letters, numbers, non-consecutive dashes, periods, underscores, and spaces.</li>
                    </ul>
                </div>
                <Button onClick={updateNickname} disabled={isDisable}>Register nickname</Button>
                <Button view="opacity" onClick={() => signOut()}>Log out</Button>
            </ModalWindowTemplate>
        </React.Fragment>
    )
}

export default StepNickname