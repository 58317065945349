import React from "react";

import {useModalsContext} from "layouts";
import {STEPS_STATUS} from "utils";

import {RejectModal, SuccessModal, ProcessModal} from "components/molecules";

const ProfileSteps = () => {
    const {currentModal, setCurrentModal} = useModalsContext()

    return (
        <React.Fragment>
            {
                {
                    [STEPS_STATUS.WALLET_SUCCESS]:
                        <SuccessModal
                            show={true}
                            text1="Success!"
                            text2={currentModal?.message}
                            isShowClose={false}
                        />,
                    [STEPS_STATUS.REJECT]:
                        <RejectModal
                            show={true}
                            text1="Oooops!"
                            isShowClose={true}
                            text2={currentModal.message}
                            closeClick={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
                        />,
                    [STEPS_STATUS.PROCESS]:
                        <ProcessModal
                            show={true}
                            text1="Waiting for signature..."
                            text2="Verify your wallet"
                        />
                }[currentModal.status]
            }
        </React.Fragment>
    )
}

export default ProfileSteps