import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import jwt_decode from "jwt-decode";
import classNames from "classnames";

import {reset, setData, userGetData, userVerificationSocial} from "store/AuthV2/AuthV2Slice"
import {useAuthorization, useConnectWallet, useGMTEvents, useLocalStorage} from "hooks";
import {globalService} from "store/Global/globalService";
import navPaths from "./navPathData.json"

import {LoadSpinnerAllPage, Image} from "components/atoms";
import {HeaderTopBanner} from "components/organisms";
import NavItem from "./ResuableComponents/NavItem";
import UserDetails from "./UserDetails";
import MenuMobile from "./menuMobile";
import AuthV2Steps from "./SignIn";

import {png} from "assets/png";
import "./style.scss"

const Header = ({stepData, setStepData, changeStep, stepDataCopy, onlyLogo = false, banner}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
        removeToken,
        removeSyncDate,
        removeLoginType,
        removeSignInNetwork,
        removeSignUpNetwork,
        isSignUpMethodAvailable,
        isSignInMethodAvailable
    } = useLocalStorage()
    const {setDataLayerDefaultData, userSignIn, userSignUp} = useGMTEvents()
    const {disconnectWallet} = useConnectWallet()
    const {isAuth} = useAuthorization()

    const [mobileMenu, setMobileMenu] = useState({open: false, section: ""})
    const {signIn, userDataXsolla, signUp, userVerification} = useSelector(state => state.authV2)

    const menuHeader = classNames("header", {"mobile-menu": mobileMenu.open && mobileMenu.section === "menu"}, {"logoCenter": onlyLogo}, {"banner-place": banner})

    useEffect(() => {
        const token = localStorage.getItem("accessToken")

        if (token) {
            if (!signIn.userData.accessToken) dispatch(userGetData(token))
        }

        if (userDataXsolla.error) {
            localStorage.removeItem("accessToken")
            reset("signIn")
            setData({path1: "signIn", path2: "userData", data: {}})
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDataXsolla.error])

    useEffect(() => {
        if (signIn.userData && !signIn.userData?.nickname && isSignInMethodAvailable()) {
            changeStep("step14")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signIn.userData])

    useEffect(() => {
        if (!stepData.currentStep) {
            if (signUp.success) {
                dispatch(reset("signUp"))
            }
            setStepData(stepDataCopy)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepData.currentStep])

    useEffect(() => {
        const href = new URL(window.location.href).searchParams
        const token = href.get("token")
        if (token) {
            try {
                let decode = jwt_decode(token)

                if (decode) {
                    dispatch(userVerificationSocial(token))
                    navigate("/")
                }
            } catch (error) {
                navigate("/")
            }
        }

        if (userVerification.success) {
            if (userVerification.registration) {
                changeStep("step8")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userVerification.success])

    useEffect(() => {
        if (isAuth) {
            globalService.checkSyncDateAndSendInventories()
            setDataLayerDefaultData()

            if (isSignInMethodAvailable()) {
                userSignIn(signIn.userData.id, isSignInMethodAvailable())
                removeSignInNetwork()
            }

            if (isSignUpMethodAvailable()) {
                userSignUp(signIn.userData.id, isSignUpMethodAvailable())
                removeSignUpNetwork()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth])

    const fieldHandle = (e) => {
        let {name, value, type} = e.target
        const stepDataCopy = {...stepData}
        if (type !== "checkbox") {
            stepDataCopy.steps[stepDataCopy.currentStep].fields[name].value = value
            setStepData(stepDataCopy)
        } else {
            stepDataCopy.steps[stepDataCopy.currentStep].fields[name].value = !stepDataCopy.steps[stepDataCopy.currentStep].fields[name].value
            setStepData(stepDataCopy)
        }
    }

    const signOut = () => {
        removeToken()
        removeSyncDate()
        removeLoginType()
        disconnectWallet()

        dispatch(setData({path1: "signIn", path2: "userData", data: {}}))
        setMobileMenu({open: false, section: ""})
        dispatch(reset("signIn"))
        navigate("/")
    }

    return (
        <React.Fragment>
            {banner && <HeaderTopBanner banner={banner}/>}
            <header className={menuHeader}>
                <div className="navigation">
                    <div className="navigation_logo">
                        <div className="navigation_logo_block">
                            <Image onClick={() => navigate("/")} src={png.LogoKompete} alt="logo"/>
                        </div>
                    </div>
                    {
                        !onlyLogo &&
                        <>
                            <div className="navigation_pages">
                                <ul>
                                    {
                                        navPaths.map((elm, i) =>
                                            <NavItem notLink={elm?.notLink} name={elm.name} path={elm.path} key={i}/>
                                        )
                                    }
                                </ul>
                            </div>
                            <div className="navigation_auth">
                                {
                                    !mobileMenu.open &&
                                    <UserDetails
                                        signOut={signOut}
                                        changeStep={changeStep}
                                        SetMobileMenu={setMobileMenu}
                                    />
                                }
                                <div className={`navigation_auth_btn-mobile ${mobileMenu.open ? "btn-close" : ""}`}
                                     onClick={() => {
                                         !mobileMenu.open ? setMobileMenu({open: true, section: "menu"}) :
                                             setMobileMenu({open: false, section: ""})
                                     }}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </header>
            {mobileMenu.open &&
                <MenuMobile
                    signOut={signOut}
                    setMobileMenu={setMobileMenu}
                    mobileMenu={mobileMenu}
                />}
            <AuthV2Steps
                stepData={stepData}
                setStepData={setStepData}
                changeStep={changeStep}
                fieldHandle={fieldHandle}
            />
            <LoadSpinnerAllPage show={userVerification.loading}/>
        </React.Fragment>
    )
}

export default Header