export const XSOLLA_STORE = {
    GET_INVENTORIES: (projectId, query) => `v2/project/${projectId}/user/inventory/items?${new URLSearchParams(query).toString()}`,
    GET_CURRENT_INVENTORY: (projectId, sku) => `/v2/project/${projectId}/items/virtual_items/sku/${sku}?additional_fields[]=custom_attributes`,
    INVENTORY_PAYMENT:(projectId,sku,currency) => `v2/project/${projectId}/payment/item/${sku + "-web2"}/virtual/${currency}`
}

export const MARKET_WS = {
    KONVERT_CHECK: "/konvert/check"
}

export const LOCKER = {
    INVENTORY: "v1/user/inventory",
    LOCK_CHECK: "v1/user/lock/check",
    LISTED_ORDER: "v1/user/listed/order",
    KONVERT_LOCK: "v1/user/konvert/lock",
    CREATE_ORDER: "v1/user/create/order",
    INVENTORY_ID: "v1/inventory/token/id",
    USER_SOCKETS: "v1/stream/user/sockets"
}

export const BAZAAR = {
    LISTED_ORDERS: "v1/user/listed/orders",
    UPDATE_LISTED_ORDER: "v1/user/update/order",
    CANCEL_LISTED_ORDER: "v1/user/cancel/order",
    CHECK_ORDERS: "v1/user/orders/expiration/check",
    CHECK_ORDER: "v1/client/check/order/availability",
    SEND_TRANSACTION_HASH: "v1/user/save/transaction/hash",
}

export const LIMITED_SHOP = {
    LIMITED_INVENTORIES: "v1/client/limited/shop",
    VOOLAH_HISTORY: "v1/user/save/voolah/spent/history"
}

export const GLOBAL = {
    SYNC_WALLET: "v1/user/wallet/sync",
    LISTED_ORDERS: "v1/client/listed/orders",
    DIVINE_INVENTORY: "v1/client/divine/shop",
    CHECK_ELIGIBILITY: "v1/user/check/eligibility",
    CREATE_SUBSCRIBER: "v1/client/create/subscriber",
    RECENTLY_VIEWeD_ITEMS: "v1/client/recent/listings",
    PRICE_HISTORY: "v1/client/inventory/price/history",
    SYNC_PRICE_HISTORY: "v1/client/inventory/price/sync",
    SECTION_ELIGIBILITY: "v1/client/get/eligibility/section",
    CHECK_NICKNAME: "v1/client/check/nickname"
}

export const PROFILE = {
    ADD_WALLET: "v1/user/add/wallet",
    REMOVE_WALLET: "v1/user/remove/wallet",
    UPDATE_NICKNAME: "v2/user/nickname/change"
}