import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {ethers} from "ethers";

import {useConnectedWallet, useConnectWallet, useLocalStorage} from "hooks";
import {authV2Service} from "store/AuthV2/AuthV2Service";
import {ERROR, errorHandler, PROCESS} from "utils";

import ModalWindowTemplate from "components/molecules/ModalWindowTemplate";
import {Button} from "components/atoms";

import "../style.scss"

const StepIsConnectWallet = ({show, changeStep}) => {
    const {isConnected, address, isWalletConnection, providers} = useConnectedWallet()
    const {setWalletConnectionSuccess, removeWalletConnectionSuccess} = useLocalStorage()
    const {disconnectWallet, openWalletModal,} = useConnectWallet()
    const {walletConnectSigner} = providers()

    const {userData} = useSelector(state => state.authV2.signIn)

    const [isDisabled, setIsDisabled] = useState(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => controlWalletConnectProcess(), [isConnected])

    const controlWalletConnectProcess = async () => {
        if (isConnected && !isWalletConnection) {
            setWalletConnectionSuccess()
            setIsDisabled(true)

            await walletConnectSigner.signMessage(PROCESS.WALLET_CONFIRM)
                .then(async (signature) => {
                    const signerAddress = ethers.utils.verifyMessage(PROCESS.WALLET_CONFIRM, signature);

                    if (signerAddress === address) {
                        await authV2Service.addWallet(address, userData.accessToken)

                        changeStep("step14")
                        disconnectWallet(true)
                    } else {
                        errorHandler(ERROR.INVALID_WALLET)
                    }
                })
                .catch((error) => {
                    disconnectWallet()
                    setIsDisabled(false)
                    removeWalletConnectionSuccess()
                    if (error.message === ERROR.ALREADY_EXISTED_WALLET && error.message !== ERROR.INVALID_WALLET) changeStep("step9")
                })
        }
    }

    return (
        <React.Fragment>
            <ModalWindowTemplate
                show={show}
                powered={true}
                privacy={true}
                className="auth-step_step6"
                isShowClose={false}
                changeStep={changeStep}
                title={<span>Want to connect a wallet to your KOMPETE Account?</span>}
            >
                <p className="auth-step_step6_text">You don’t need this to play and you can connect it later in your
                    profile.</p>
                <div className="auth-step_step6_buttons">
                    <Button disabled={isDisabled} view="secondary" onClick={() => openWalletModal()}>Yes</Button>
                    <Button disabled={isDisabled} view="opacity" onClick={() => changeStep("step14")}>No</Button>
                </div>
            </ModalWindowTemplate>
        </React.Fragment>
    )
}

export default StepIsConnectWallet