import React from "react";

import {useModalsContext} from "layouts";
import {STEPS_STATUS} from "utils";

import {LoginAlertModal, ProcessModal, RejectModal, SuccessModal} from "components/molecules";
import DivinePurchaseSuccessfulModal from "./DivinePurchaseSuccessfulModal";
import DivineWeb2BrowserAlertModal from "./DivineWeb2BrowserAlertModal";
import DivineOrderModal from "./DivineOrderModal";

const DivineSteps = ({changeStep}) => {
    const {currentModal, setCurrentModal} = useModalsContext()

    return (
        <React.Fragment>
            {
                {
                    [STEPS_STATUS.ORDER]:
                        <DivineOrderModal
                            show={true}
                            setWhichModalOpen={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
                        />,
                    [STEPS_STATUS.REJECT]:
                        <RejectModal
                            show={true}
                            text1="Oooops!"
                            isShowClose={true}
                            text2={currentModal.message}
                            closeClick={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
                        />,
                    [STEPS_STATUS.PROCESS]:
                        <ProcessModal
                            show={true}
                            text1="Waiting for signature..."
                            text2="After wallet approval, your transaction will be finished shortly."
                        />,
                    [STEPS_STATUS.SUCCESS]:
                        <DivinePurchaseSuccessfulModal
                            show={true}
                            setWhichModalOpen={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
                        />,
                    [STEPS_STATUS.LOGIN]:
                        <LoginAlertModal
                            show={true}
                            changeStep={changeStep}
                            closeClick={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
                        />,
                    [STEPS_STATUS.WALLET_SUCCESS]:
                        <SuccessModal
                            show={true}
                            text1="Success!"
                            isShowClose={false}
                            text2={currentModal.message}
                        />,
                    [STEPS_STATUS.WEB2_BROWSER]:
                        <DivineWeb2BrowserAlertModal
                            show={true}
                            changeStep={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
                        />
                }[currentModal.status]
            }
        </React.Fragment>
    )
}
export default DivineSteps