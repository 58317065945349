import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import React from "react";

import {useAuthorization, useConnectedWallet, useConnectWallet, useDetectMobile} from "hooks";
import {useModalsContext} from "layouts";
import {STEPS_STATUS} from "utils";

import {Button} from "components/atoms";

const BuyButton = ({data, quantity, isLoading, isEligibilityAvailable, isEligible}) => {
    const navigate = useNavigate()

    const {userData} = useSelector(state => state.authV2.signIn)

    const {setCurrentModal} = useModalsContext()
    const {openWalletModal} = useConnectWallet()
    const {isMobile} = useDetectMobile()
    const {isConnected,} = useConnectedWallet()
    const {isAuth, accessToken} = useAuthorization()

    const isProviderAvailable = typeof window.ethereum !== 'undefined' || typeof window.web3 !== 'undefined'

    const determineOnClickAction = () => {
        if (!isAuth)
            return setCurrentModal({status: STEPS_STATUS.LOGIN})

        if (isAuth && !userData.wallet)
            navigate("/profile")

        if (isEligibilityAvailable && !isEligible)
            return () => {
            }

        return isConnected
            ? setCurrentModal({status: STEPS_STATUS.ORDER, data: {inventory: data.inventory, quantity}})
            : isMobile ? isProviderAvailable ? openWalletModal() : setCurrentModal({status: STEPS_STATUS.WEB2_BROWSER}) : openWalletModal()
    }

    const determineDisplayText = () => {
        if (!isAuth)
            return accessToken ? "" : "Sign In";

        if (isAuth && !userData.wallet)
            return "Add Wallet"

        if (isEligibilityAvailable && !isEligible)
            return "REQUIREMENTS NOT MET!";

        return isConnected ? "Buy Now" : "Connect Wallet";
    }

    const buttonDetails = {
        onClick: () => determineOnClickAction(),
        text: () => determineDisplayText()
    }

    return (
        <React.Fragment>
            {!isLoading && data && (
                <Button onClick={() => buttonDetails.onClick()}>
                    {buttonDetails.text()}
                </Button>
            )}
        </React.Fragment>
    )
}

export default BuyButton