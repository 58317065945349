import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {useEffect} from "react";

import {
    wait,
    ERROR,
    STEPS_STATUS,
    errorHandler,
    formatNumberToEuropeanStyle,
    walletAndTransactionErrorHandle
} from "utils";
import {useConnectedWallet, useConnectWallet} from "hooks";
import useDivineShop from "../useDivineShop";
import {useModalsContext} from "layouts";

import ModalWindowTemplate from "components/molecules/ModalWindowTemplate";
import PaymentMethod from "components/molecules/PaymentMethodTemplate";
import {Button, Rarity, Image} from "components/atoms";

import "../style.scss"

const DivineOrderModal = ({show}) => {
    const navigate = useNavigate()

    const {disconnectWallet, openWalletModal} = useConnectWallet()
    const {
        isConnected,
        address,
        getEthBalance,
        providers,
        isConnectedAddressCorrect,
        isConnectedChainCorrect
    } = useConnectedWallet()
    const {currentModal, setCurrentModal} = useModalsContext()

    const {currentChain} = useSelector(state => state.web3)
    const {ethBalance, wallet} = useSelector(state => state.authV2.signIn.userData)

    const {inventory, quantity} = currentModal.data

    const currentInventoryEthPrice = quantity * inventory.eth_price
    const formattedEthBalance = ethBalance ? Number(formatNumberToEuropeanStyle(ethBalance)).toFixed(5) : 0
    const isEthBalanceCorrect = Number(ethBalance) >= currentInventoryEthPrice
    const {walletConnectSigner} = providers()

    useEffect(() => {
        if (!ethBalance) getEthBalance(address)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {createSellOrder, createBuyOrder, sendTransaction} = useDivineShop({
        chainId: currentChain.chainId,
        feePercent: 90,
        price: currentInventoryEthPrice.toString(),
        signer: walletConnectSigner,
        quantity,
        tokenId: inventory.token_id,
        account: wallet
    })

    const mintInventory = async () => {
        if (!isConnectedChainCorrect) {
            setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.INVALID_CHAIN})
            disconnectWallet()
            return
        }

        if (!isConnectedAddressCorrect) {
            setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.WALLET_CONNECTED_MATCH_INVALID})
            disconnectWallet()
            return
        }

        setCurrentModal({status: STEPS_STATUS.PROCESS})

        try {
            const {sellOrder, sellSignature} = await createSellOrder()

            const {buyOrder, buySignature} = await createBuyOrder()

            await wait(4000)

            try {
                const result = await sendTransaction(sellOrder, sellSignature, buyOrder, buySignature)
                if (!result.status)
                    errorHandler(ERROR.TRANSACTION_FAILED)

                setCurrentModal({status: STEPS_STATUS.SUCCESS, data: currentModal.data})
                getEthBalance(address)
            } catch (error) {
                const {message} = error
                let errorMessage = message.length > 100 ? walletAndTransactionErrorHandle(error, false) : message
                setCurrentModal({status: STEPS_STATUS.REJECT, message: errorMessage})
            }
        } catch (error) {
            setCurrentModal({status: STEPS_STATUS.REJECT, message: error.message})
        }
    }

    const methodDetails = {
        eth: {
            text: isConnected ? isEthBalanceCorrect ? "Pay with eth" : "Buy eth" : "Connect wallet",
            onClick: () =>
                isConnected
                    ? isEthBalanceCorrect
                        ? mintInventory()
                        : navigate("/buy-kompete-token", {state: {process: "order-more-eth"}})
                    : (setCurrentModal({status: STEPS_STATUS.CLOSE}), openWalletModal()),
            error: isConnected ? isEthBalanceCorrect ? "" : "Insufficient ETH balance.Please purchase more ETH!" : "",
            totalPrice: `${currentInventoryEthPrice.toFixed(3)} ETH`
        }
    }

    return (
        <ModalWindowTemplate
            show={show}
            powered={true}
            size="modal-lg"
            isShowClose={true}
            title="Order Summary"
            className="shop-divine_order-summery"
            changeStep={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
        >
            <div className="shop-divine_order-summery_content">
                <div className="shop-divine_order-summery_content_info">
                    <div className="shop-divine_order-summery_content_info_img divine-small">
                        <Image alt="cosmetic" src={inventory.image_url}/>
                    </div>
                    <div className="shop-divine_order-summery_content_info_details">
                        <div className="shop-divine_order-summery_content_info_details_title">
                            <h6>{inventory.name}</h6>
                            <Rarity
                                size="md"
                                text={inventory.rarity}
                                rarity={inventory.rarity}
                            />
                        </div>
                        <div className="shop-divine_order-summery_content_info_details_qantity">
                            <p>X {quantity}</p>
                        </div>
                    </div>
                </div>
                <div className="shop-divine_order-summery_content_payments-methods">
                    <div className="shop-divine_order-summery_content_payments-methods_head">
                        <span className="shop-divine_order-summery_content_payments-methods_head_quantity">
                            Available payment methods (1)
                        </span>
                        {!!methodDetails.eth.error &&
                            <span className="shop-divine_order-summery_content_payments-methods_head_error">
                             {methodDetails.eth.error}
                            </span>}
                    </div>
                    <PaymentMethod
                        method="eth"
                        checked={true}
                        disable={true}
                        balance={formattedEthBalance}
                        insufficient={!!methodDetails.eth.error}
                    />
                </div>
                <div className="shop-divine_order-summery_content_total">
                    <div>Total</div>
                    <div>{methodDetails.eth.totalPrice}</div>
                </div>
                {!!methodDetails.eth.error &&
                    <div className="shop-divine_order-summery_content_mobile-error">
                        {methodDetails.eth.error}
                    </div>}
                <div className="shop-divine_order-summery_content_buttons">
                    <Button
                        view="secondary"
                        onClick={() => methodDetails.eth.onClick()}
                    >
                        {methodDetails.eth.text}
                    </Button>
                </div>
            </div>
        </ModalWindowTemplate>
    )
}

export default DivineOrderModal