import React, {memo} from "react";

import ProfilePasswordBlock from "./ProfilePasswordBlock";
import ProfileNicknameBlock from "./ProfileNicknameBlock";
import ProfileWalletBlock from "./ProfileWalletBlock";
import ProfileAvatarBlock from "./ProfileAvatarBlock";
import ProfileEmailBlock from "./ProfileEmailBlock";
import "./style.scss"


const ProfileSetting = () => (
    <section className="profile">
        <div className="profile_container">
            <h1>Profile Settings</h1>
            <ProfileAvatarBlock/>
            <ProfileNicknameBlock/>
            <ProfileEmailBlock/>
            <ProfilePasswordBlock/>
            <ProfileWalletBlock/>
        </div>
    </section>
)

export default memo(ProfileSetting)